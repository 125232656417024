<template>
<div class="home-outer">
    <el-carousel indicator-position="outside">
        <el-carousel-item v-for="(item, index) in imageslist" :key="index">
            <img class="imgs" id="oimg" :src="item" />
        </el-carousel-item>
    </el-carousel>

    <div class="home custom-width">
        <div v-for="(litem,lindx) in blist" :key="litem.Brand_ID">
            <template v-if="newList.length>0&&newList[lindx].list&&newList[lindx].list.length>0">
                <h5>{{litem.Brand_Name}}</h5>
                <el-row class="products">
                        <el-col :span="6" v-for="(item,index) in newList[lindx].list" :key="index">
                            <div class="prods" @click="jump(item.Product_ID)">
                                <div class="podtImg">
                                    <img class="" :src="'https://www.visasiv.cn' +item.Product_ImageUrl" alt="" srcset="">
                                </div>
                                <p>{{item.Product_Name}}</p>
                                <div>
                                    <span>￥<strong> {{item.Product_SellPrice}}</strong> 元</span>
                                </div>
                            </div>
                        </el-col>
                </el-row>
            </template>
        </div>
    </div>
</div>
</template>

<script>
let app = null
import productImg from "@/assets/product.jpg"
import bn1 from "@/assets/bn1.png"
import bn2 from "@/assets/bn2.png"
import bn3 from "@/assets/bn3.png"

export default {
    data() {
        return {
            domin: "",
            imageslist: [bn1, bn2, bn3],
            blist: [{
                Brand_ID: 7,
                Brand_Name: "亲子产品"
            }, {
                Brand_ID: 8,
                Brand_Name: "研学产品"
            }, {
                Brand_ID: 9,
                Brand_Name: "科普实验"
            }, {
                Brand_ID: 10,
                Brand_Name: "户外拓展"
            }, {
                Brand_ID: 11,
                Brand_Name: "假日特供"
            }, ],
            newList: []
        }
    },
    created() {
        document.title = this.$route.meta.title
        console.log(this.$route.meta.title)
        app = this.$.appContext.app
        this.domin = app.domin
        console.log(app.domin)
        this.getDetail()
    },
    methods: {
        jump(id) {
           let tempUrl = this.$router.push({
                path: '/details/' + id,
                
            })

            // window.open(tempUrl.href,'_blank')
        },
        async getDetail() {

            let Brands = this.blist.map(item => item.Brand_ID)
            console.log("Brands:", Brands)

            let PromiseBlist = Brands.map(elem => {
                return new Promise((resolve, reject) => {
                    app.wpost({
                        url: "/Ajax/WordWelfare/getproductlist",
                        data: {
                            brandid: elem,
                            pagesize:20
                        },
                        succ: (data) => {
                            resolve(data)
                        }
                    })
                })
            });

            let allProResult = await Promise.all(PromiseBlist)
            this.newList = allProResult
            console.log("allProResult", allProResult)

        }
    }
}
</script>

<style scoped>
.home-outer{
    padding-bottom: 60px;
}
.home h5 {
    color: #F56C6C;
    font-size: 15px;
}

.el-carousel {
    text-align: center;
    background-color: #fafafa;
    margin: 20px auto;
}

.products {
    border: 1px solid #dedede;
    box-sizing: border-box;
    padding: 30px 10px;
    margin: 20px auto;
}

.prods:hover {
    cursor: pointer;
    background-color: #fafafa;
}

.prods img {
    width: 90%;
    padding: 10px;
}

.prods strong {
    font-size: 16px;
    color: firebrick;
}

.podtImg {
    width: 98%;
    height: 170px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    background-color: #fafafa;
    box-shadow: 0 0 3px #fafafa;
}
</style>
